

export default class {

    constructor(awsRegion, awsEndpoint, awsAccessKey, awsAccessSecret, awsSessionToken) {
        this.awsRegion = awsRegion;
        this.awsEndpoint = awsEndpoint;
        this.awsAccessKey = awsAccessKey;
        this.awsAccessSecret = awsAccessSecret;
        this.awsSessionToken = awsSessionToken;
    }

    initClient() {
        const now = new Date();
        const clientId = "VueApp_" + now.getTime().toString(16) + Math.random().toString(16).substr(2, 8);
        const _client = new Paho.MQTT.Client(this.getEndPoint(), clientId);

        // publish
        _client.publish = function (topic, payload) {
            let payloadJson = JSON.stringify(payload);
            let message = new Paho.MQTT.Message(payloadJson);
            message.destinationName = topic;
            message.qos = 1;
            _client.send(message);
        }

        return _client;
    }

    getEndPoint() {
        function p4() { }
        p4.sign = function (key, msg) {
            const hash = CryptoJS.HmacSHA256(msg, key);
            return hash.toString(CryptoJS.enc.Hex);
        };
        p4.sha256 = function (msg) {
            const hash = CryptoJS.SHA256(msg);
            return hash.toString(CryptoJS.enc.Hex);
        };
        p4.getSignatureKey = function (key, dateStamp, regionName, serviceName) {
            const kDate = CryptoJS.HmacSHA256(dateStamp, 'AWS4' + key);
            const kRegion = CryptoJS.HmacSHA256(regionName, kDate);
            const kService = CryptoJS.HmacSHA256(serviceName, kRegion);
            const kSigning = CryptoJS.HmacSHA256('aws4_request', kService);
            return kSigning;
        };

        // date & time 
        const dt = (new Date()).toISOString().replace(/[^0-9]/g, "");
        const ymd = dt.slice(0, 8);
        const fdt = `${ymd}T${dt.slice(8, 14)}Z`

        const scope = `${ymd}/${this.awsRegion}/iotdevicegateway/aws4_request`;
        const ks = encodeURIComponent(`${this.awsAccessKey}/${scope}`);
        let qs = `X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=${ks}&X-Amz-Date=${fdt}&X-Amz-SignedHeaders=host`;

        const req = `GET\n/mqtt\n${qs}\nhost:${this.awsEndpoint}\n\nhost\n${p4.sha256('')}`;

        if (this.awsSessionToken) {
            qs += "&X-Amz-Security-Token=" + encodeURIComponent(this.awsSessionToken);
        }

        qs += '&X-Amz-Signature=' + p4.sign(
            p4.getSignatureKey(this.awsAccessSecret, ymd, this.awsRegion, 'iotdevicegateway'),
            `AWS4-HMAC-SHA256\n${fdt}\n${scope}\n${p4.sha256(req)}`
        );

        return `wss://${this.awsEndpoint}/mqtt?${qs}`;
    }
    async getClient(success) {
        const _client = await this.initClient(); // 等待 initClient 完成
        const connectOptions = {
            useSSL: true,
            timeout: 3,
            mqttVersion: 4,
            onSuccess: success
        };
    
        const connectionPromise = new Promise((resolve) => {
            connectOptions.onSuccess = () => {
                console.log("connected");
                resolve(_client);
            };
        });
    
        _client.connect(connectOptions); // 不需要 await 這個操作
    
        return connectionPromise;
    }
    
}