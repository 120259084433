import ApiService from './ApiService';
import mqttClient from './mqttClient';
import io from "socket.io-client";


export const communicateMixin = {
  data() {
    return {
      socket: null,
      deviceStatus: {        
        "IsServerConnect": null
      },
      iotClient: null,
      iotType: null,
      hostPath: null
    }
  },
  async mounted() {
    await this.getIotType();
    console.log(this.iotType);
    if (this.iotType == "socket") {
      this.initSocket();
    } else {
      this.setHostPath();
      this.initAwsIot();
    }
  },
  methods: {
    async getIotType() {
      const api = new ApiService("SysParameter");
      const requestData = {
        ParametervarName: "IotType",
        ParametervarSect: "IotType"
      }      
      this.iotType = (await api.getAll(requestData)).Data[0].ParametervarValue;
    },
    publishMessage(topic, message) {
      message = JSON.stringify(message);
      if (this.iotType == "socket") {
        topic = "sendMsg";
        this.socket.emit(topic, message);
      } else {
        console.log(this.hostPath + '/' + topic, message);
        this.iotClient.publish(this.hostPath + '/' + topic, message);
      }
    },
    async initSocket() {
      this.socket = io(await this.$root.$data.store.getSocketEndpoint());
      this.socket.on("error", () => {
        this.deviceStatus.IsServerConnect = false;
        console.log("Disconnected");
      });
      this.socket.on("connect", () => {
        this.deviceStatus.IsServerConnect = true;
        console.log("Connected");
      });
      this.socket.on("disconnect", () => {
        this.deviceStatus.IsServerConnect = false;
        console.log("Disconnected");
      });
      this.socket.on("getMsg", message => {
        this.receivedMessageHandling(message);
      });
    },
    receivedMessageHandling(message) {
      try {
        var jsonMessage = JSON.parse(message);
        // message = JSON.parse(message);
        // jsonMessage = this.iotType == "iot" ? JSON.parse(message) : message; // iot版的string很神奇 一定要這樣才能轉成json 要比較差別的話直接console.log(typeof(message))看看
        /*
        if ('IsGanglionConnected' in jsonMessage) //新版 
        {
          this.deviceStatus = { ...this.deviceStatus, "IsDeviceConnect": true, ...jsonMessage };  //有值更新，沒值保留
        }
        else if ('Connection' in jsonMessage) //舊版
        {
          this.deviceStatus = { ...this.deviceStatus, "IsDeviceConnect": true, "IsGanglionConnected": jsonMessage.Connection }
        }
        */
        this.messageProcessing(jsonMessage);
      }
      catch (error) { // 不是json就會進到error

      }

    },

    async initAwsIot() {
      const awsApi = new ApiService("aws");
      const cred = await awsApi.post("", "credential/iot");
      const mqtt = new mqttClient(cred.Region, cred.IotEndPoint, cred.Credential.AccessKeyId, cred.Credential.SecretAccessKey, cred.Credential.SessionToken);

      this.iotClient = await mqtt.getClient();
      this.deviceStatus.IsServerConnect = this.iotClient.isConnected()
      this.iotClient.onMessageArrived = message => {
        this.receivedMessageHandling(message.payloadString);
      };
      this.iotClient.onConnectionLost = e => {
        this.deviceStatus.IsServerConnect = false;
        console.log('onConnectionLost', e);
      };
    },
    disconnectIot(){
      if(this.iotType == "iot") this.iotClient.disconnect();
    },
    setHostPath() {
      const domainName = location.host;
      if (domainName.includes("localhost") || domainName.includes("dev")) {
        this.hostPath = "dev";
      } else {
        this.hostPath = "master";
      }
    },
    subscribeIOTChannel(deviceId) {
      if (this.iotType == "iot") this.iotClient.subscribe(this.hostPath + '/' + deviceId);
    },
  }
}
